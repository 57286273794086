<template>
  <div>
    <!-- 筛选区域 -->
    <el-row class="row_line" :gutter="24">
      <el-form size="mini">
        <el-col :xs="24" :sm="12" :md="7" :lg="7" :xl="7">
          <el-form-item label="Affiliate Id" label-width="100px">
            <group-select
              v-model="affiliateIds"
              :data-source="affiliatesGroupList"
              multiple
              reserve-keyword
              :loading="affiliateLoading"
              clearable
              filterable
              size="mini"
              class="w100"
              placeholder="Please select"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="7" :lg="7" :xl="7" :align="fullWidth < 750 ? 'right' : ''">
          <el-form-item>
            <el-button type="primary" @click="getList(1)">Search</el-button>
            <el-button type="success" @click="openDialog('create')">Create</el-button>
            <el-button type="danger" v-if="selectionList.length > 0" @click="delAffiliate"
              >Delete
            </el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <!-- 弹窗区域 -->
    <el-dialog
      title="Enabled Affiliates"
      :visible.sync="dialog.dialogFormVisible"
      width="99%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
    >
      <el-form
        :model="enabledAffiliatesForm"
        ref="enabledAffiliatesForm"
        label-width="100px"
        size="small"
      >
        <div v-for="(eachItem, index) in enabledAffiliatesForm.enabledAffiliates" :key="index">
          <el-form-item
            label="AffiliateId"
            :prop="'enabledAffiliates.' + index + '.affiliateIds'"
            :rules="{
              required: true,
              message: 'AffiliateId不能为空',
              trigger: ['blur', 'change'],
            }"
          >
            <group-select
              v-model="eachItem.affiliateIds"
              :data-source="getDataSource(index)"
              :loading="affiliateLoading"
              clearable
              filterable
              size="small"
              style="width: 50%;"
              @change="handleChangeAff($event, originAffiliateIds, index)"
              @focus="handleFocusAff"
              placeholder="Please select"
              @clear="handleClear($event)"
            />
            <el-button
              type="primary"
              icon="el-icon-plus"
              circle
              size="mini"
              v-if="index == 0"
              style="margin-left: 20px;"
              @click="addItem(0, 'parent')"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="mini"
              v-if="index > 0"
              style="margin-left: 20px;"
              @click="delItem(index, 0, 'parent')"
            ></el-button>
          </el-form-item>
          <div v-for="(childItem, childIndex) in eachItem.enabledAffiliatesItem" :key="childIndex">
            <el-row :gutter="5">
              <el-col :span="4">
                <el-form-item
                  label="Event Name"
                  :prop="
                    'enabledAffiliates.' +
                      index +
                      '.enabledAffiliatesItem.' +
                      childIndex +
                      '.eventName'
                  "
                  :rules="{
                    required: true,
                    message: 'EventName不能为空',
                    trigger: ['blur', 'change'],
                  }"
                >
                  <el-select v-model="childItem.eventName" placeholder="Please select" clearable>
                    <el-option label="all-event" value="all-event"></el-option>
                    <el-option label="install" value="install"></el-option>
                    <el-option
                      v-for="item in dataList"
                      :key="item.eventName"
                      :label="item.eventName"
                      :value="item.eventName"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item
                  label="DailyCap"
                  :prop="
                    'enabledAffiliates.' +
                      index +
                      '.enabledAffiliatesItem.' +
                      childIndex +
                      '.dailyCap'
                  "
                  :rules="[
                    {
                      required: true,
                      message: '不能为空',
                      trigger: ['blur', 'change'],
                    },
                    {
                      type: 'number',
                      message: '必须为数字值',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                  label-width="80px"
                >
                  <el-input
                    v-model.number="childItem.dailyCap"
                    placeholder="add cap here"
                    style="width: 110px;"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <!-- <el-form-item label="ClickDailyCap" prop="">
                  <el-input v-model="enabledAffiliates.clickDailyCap" placeholder="add cap here"></el-input>
                </el-form-item> -->
                <!-- :prop="enabledAffiliates.payoutPercent == '' ? 'payout' : ''" -->
                <el-form-item
                  label="Payout"
                  :prop="
                    'enabledAffiliates.' +
                      index +
                      '.enabledAffiliatesItem.' +
                      childIndex +
                      '.payout'
                  "
                  label-width="75px"
                >
                  <el-input
                    v-model="childItem.payout"
                    :disabled="childItem.payoutPercent !== ''"
                    placeholder="add payout here"
                    style="width: 130px;"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <!-- :prop="enabledAffiliates.payout == '' ? 'payoutPercent' : ''" -->
                <el-form-item
                  label="Payout per"
                  :prop="
                    'enabledAffiliates.' +
                      index +
                      '.enabledAffiliatesItem.' +
                      childIndex +
                      '.payoutPercent'
                  "
                  label-width="115px"
                >
                  <el-input
                    v-model="childItem.payoutPercent"
                    :disabled="childItem.payout !== ''"
                    placeholder="payoutPercent"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="Adjust"
                  label-width="55px"
                  :prop="
                    'enabledAffiliates.' + index + '.enabledAffiliatesItem.' + childIndex + '.rate'
                  "
                >
                  <el-input v-model="childItem.rate" placeholder="add adjust rate">
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="Description"
                  :prop="
                    'enabledAffiliates.' +
                      index +
                      '.enabledAffiliatesItem.' +
                      childIndex +
                      '.description'
                  "
                >
                  <el-input v-model="childItem.description" placeholder="Please enter"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  circle
                  size="mini"
                  v-if="
                    childIndex === 0 &&
                      childItem.eventName !== 'all' &&
                      eachItem.enabledAffiliatesItem.length <= dataList.length
                  "
                  @click="addItem(index, 'child')"
                ></el-button>
                <el-button
                  type="danger"
                  v-if="childIndex > 0"
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  @click="delItem(index, childIndex, 'child')"
                ></el-button>
              </el-col>
            </el-row>
          </div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="clickDailyCap"
                label-width="105px"
                :prop="'enabledAffiliates.' + index + '.clickDailyCap'"
                :rules="[
                  { required: true, message: 'ClickDailyCap不能为空', trigger: ['blur'] },
                  {
                    type: 'number',
                    message: 'clickDailyCap必须为数字值',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input v-model.number="eachItem.clickDailyCap" placeholder=""></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small">取 消</el-button>
        <el-button
          type="primary"
          :loading="addBtnLoading"
          @click="submitDialog('enabledAffiliatesForm')"
          size="small"
          >确 定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="修改批单"
      :visible.sync="dialogUpdateApprove.updateApproveDialogFormVisible"
      append-to-body
    >
      <el-form
        :model="formUpdateApprove"
        ref="formUpdateApprove"
        label-width="120px"
        :rules="rulesUpdate"
      >
        <el-form-item label="Affiliate Id" prop="affiliateId">
          <group-select
            v-model="formUpdateApprove.affiliateId"
            :data-source="affiliatesGroupList"
            reserve-keyword
            :loading="affiliateLoading"
            filterable
            size="mini"
            class="w100"
            placeholder="Please select"
          />
        </el-form-item>

        <el-form-item label="Event Name" prop="eventName">
          <el-select
            v-model="formUpdateApprove.eventName"
            placeholder="Please select"
            size="mini"
            class="w100"
          >
            <el-option label="all-event" value="all-event"></el-option>
            <el-option label="install" value="install"></el-option>
            <el-option
              v-for="item in dataList"
              :key="item.eventName"
              :label="item.eventName"
              :value="item.eventName"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="DailyCap" prop="dailyCap">
          <el-input
            v-model.number="formUpdateApprove.dailyCap"
            placeholder="add cap here"
            size="mini"
            class="w100"
          ></el-input>
        </el-form-item>

        <el-form-item label="clickDailyCap" prop="clickDailyCap">
          <el-input
            v-model.number="formUpdateApprove.clickDailyCap"
            placeholder=""
            size="mini"
            class="w100"
          ></el-input>
        </el-form-item>

        <el-form-item label="Payout">
          <el-input
            v-model="formUpdateApprove.payout"
            placeholder="add payout here"
            size="mini"
            class="w100"
            :disabled="formUpdateApprove.payoutPercent !== ''"
          ></el-input>
        </el-form-item>

        <el-form-item label="Payout per">
          <el-input
            v-model="formUpdateApprove.payoutPercent"
            placeholder="payoutPercent"
            size="mini"
            class="w100"
            :disabled="formUpdateApprove.payout !== ''"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>

        <el-form-item label="Adjust">
          <el-input
            v-model="formUpdateApprove.rate"
            placeholder="add adjust rate"
            size="mini"
            class="w100"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>

        <el-form-item label="Description">
          <el-input
            v-model="formUpdateApprove.description"
            placeholder="Please enter"
            size="mini"
            class="w100"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUpdateApprove.updateApproveDialogFormVisible = false"
          >取 消
        </el-button>
        <el-button
          type="primary"
          :loading="updateApproveBtnLoading"
          @click="submitUpdateApproveDialog('formUpdateApprove')"
          >确 定
        </el-button>
      </div>
    </el-dialog>

    <!-- 列表区域 -->
    <el-table
      :data="list"
      highlight-current-row
      @selection-change="handleenabledAffiliatesSelectionChange"
      border
      stripe
      size="mini"
      class="w100"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="AffiliateId" prop="affiliateId" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="AffiliateName"
        prop="affiliateName"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Event Name" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.eventName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Payout" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.payoutValue }}</span>
        </template>
      </el-table-column>
      <el-table-column label="DailyCap" prop="dailyCap" align="center">
        <template slot-scope="scope">
          {{ scope.row.dailyCap }}
        </template>
      </el-table-column>
      <el-table-column label="ClickDailyCap" prop="clickDailyCap" align="center">
        <template slot-scope="scope">
          {{ scope.row.clickDailyCap }}
        </template>
      </el-table-column>
      <el-table-column label="Payout Rate" prop="apiSync" align="center">
        <template slot-scope="scope">
          {{ scope.row.payoutPercent || '' }} {{ scope.row.payoutPercent ? '%' : '' }}
        </template>
      </el-table-column>
      <el-table-column label="Adjust Rate" prop="rate" align="center">
        <template slot-scope="scope">
          {{ scope.row.rate || '' }} {{ scope.row.rate ? '%' : '' }}
        </template>
      </el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column label="enable" prop="enable" align="center"></el-table-column> -->
      <el-table-column label="createTime" prop="operateTime" align="center"></el-table-column>

      <el-table-column label="action" width="210" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            circle
            size="mini"
            @click="openUpdateApproveDialog('create', scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 批单提示框 -->
    <el-dialog title="批单信息" :visible.sync="toAffVisible" append-to-body>
      <el-table :data="tipsData" stripe border>
        <el-table-column label="AffiliateId" prop="affiliateId" align="center"></el-table-column>
        <el-table-column label="Type & Msg" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.type">{{ scope.row.msg }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="OfferIds" prop="offerIds" align="center"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 分页区域 -->
    <!--    <el-row>
      <el-col :span="24" align="center">
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleenabledAffiliatesSizeChange"
          @handleCurrentChange="handleenabledAffiliatesCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
      </el-col>
    </el-row>-->
  </div>
</template>

<script>
  // import { mapState, mapActions } from 'vuex';
  // import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import {
    approvalAfffs,
    fetchSingleApproveInfo,
    handleApproveUpdate,
  } from 'api/product/rankSearch';
  import { deleteOfferWhiteList } from 'api/affiliate/detail';
  import { fetchEnabledAffiliatesPage } from 'api/offer/detail';
  import { uniq } from 'lodash-es';

  let clickTimer = null;
  export default {
    components: {
      // Pagination,
      GroupSelect,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
      dataList: {
        type: Array,
        default: () => [],
      },
      affiliatesGroupList: {
        type: Array,
        default: () => [],
      },
      affiliateLoading: {
        default: false,
      },
    },
    data() {
      return {
        affiliateIds: [],
        affiliateIdsArr: [],
        checkedAffiliateIds: [], // 已经选择的AffiliateIds
        originAffiliateIds: '', // 原来的AffiliateIds
        enabledAffiliatesForm: {
          enabledAffiliates: [
            {
              optionAffiliateIds: [],
              affiliateIds: '',
              clickDailyCap: '',
              enabledAffiliatesItem: [
                {
                  eventName: '',
                  dailyCap: '',
                  payout: '',
                  payoutPercent: '',
                  rate: '',
                  description: '',
                },
              ],
            },
          ],
        },
        formUpdateApprove: {
          affiliateId: '',
          eventName: '',
          dailyCap: '',
          payout: '',
          payoutPercent: '',
          rate: '',
          description: '',
          id: '',
          clickDailyCap: '',
        },
        eventNameList: [],
        toAffVisible: false,
        tipsData: [],
        addBtnLoading: false,
        updateApproveBtnLoading: false,
        delArr: [],
        list: [],
        total: null,
        selectionList: [],
        deleteAffIds: [],
        pageSize: 10,
        currentPage: 1,
        dialog: {
          dialogFormVisible: false,
          type: '',
        },
        dialogUpdateApprove: {
          updateApproveDialogFormVisible: false,
          type: '',
        },
        fullWidth: document.documentElement.clientWidth,
        rules: {
          affiliateIds: [{ required: true, message: 'Please select affiliate', trigger: 'blur' }],
          dailyCap: [{ required: true, message: 'Please enter dailyCap', trigger: 'blur' }],
          // payout: [{ required: true, message: 'Please enter payout', trigger: 'blur' }],
          // payoutPercent: [
          //   { required: true, message: 'Please enter payoutPercent', trigger: 'blur' },
          // ],
          // rate: [{ required: true, message: 'Please enter rate', trigger: 'blur' }],
          // description: [{ required: true, message: 'Please enter description', trigger: 'blur' }],
        },
        rulesUpdate: {
          affiliateId: [{ required: true, message: 'Please select affiliate', trigger: 'blur' }],
          dailyCap: [
            { required: true, message: '不能为空', trigger: ['blur', 'change'] },
            {
              type: 'number',
              message: '必须为数字值',
              trigger: ['blur', 'change'],
            },
          ],
          clickDailyCap: [
            { required: true, message: 'ClickDailyCap不能为空', trigger: ['blur'] },
            {
              type: 'number',
              message: 'clickDailyCap必须为数字值',
              trigger: ['blur', 'change'],
            },
          ],
          eventName: [{ required: true, message: '不能为空', trigger: ['blur'] }],
        },
      };
    },
    computed: {
      // ...mapState('affiliate', {
      //   affiliatesGroupList: (state) => state.affiliatesGroupList,
      //   affiliateLoading: (state) => state.affiliateLoading,
      // }),
      // payoutChange() {
      //   const { payout, payoutPercent } = this.enabledAffiliatesForm.enabledAffiliates;
      //   return { payout, payoutPercent };
      // },
    },
    watch: {
      // payoutChange: function() {
      //   console.log('sssa');
      //   this.$refs.enabledAffiliates.validate();
      // },
    },
    mounted() {
      // this.getAffiliateList();
      this.getList();
    },
    methods: {
      // ...mapActions('affiliate', ['getAffiliateList']),

      openUpdateApproveDialog(type, rowData) {
        const param = {
          id: rowData.id,
        };
        // 执行操作
        fetchSingleApproveInfo(param).then((response) => {
          if (response.code === 200) {
            this.dialogUpdateApprove.type = type;
            this.dialogUpdateApprove.updateApproveDialogFormVisible = true;
            this.formUpdateApprove.affiliateId = response.result.affiliateId;
            this.formUpdateApprove.eventName = response.result.eventName;
            this.$set(this.formUpdateApprove, 'dailyCap', response.result.dailyCap);
            this.$set(this.formUpdateApprove, 'clickDailyCap', response.result.clickDailyCap);
            if (response.result.payoutValue) {
              this.$set(this.formUpdateApprove, 'payout', response.result.payoutValue);
            } else {
              this.$set(this.formUpdateApprove, 'payout', '');
            }
            if (response.result.payoutPercent) {
              this.$set(this.formUpdateApprove, 'payoutPercent', response.result.payoutPercent);
            } else {
              this.$set(this.formUpdateApprove, 'payoutPercent', '');
            }
            if (response.result.rate) {
              this.$set(this.formUpdateApprove, 'rate', response.result.rate);
            } else {
              this.$set(this.formUpdateApprove, 'rate', '');
            }
            this.formUpdateApprove.description = response.result.description;
            this.formUpdateApprove.id = response.result.id;
          }
        });
      },

      /**
       * 获取列表
       * @param {Object} curPage
       * curpage 分页参数
       */
      getList(curPage) {
        // 判断分页
        if (curPage) {
          this.currentPage = curPage;
        }
        // 拼接参数
        const param = {
          affiliateIds: this.affiliateIds.join(','),
          page: this.currentPage,
          pageSize: this.pageSize,
          offerIds: this.offerId,
        };
        // 执行操作
        fetchEnabledAffiliatesPage(param).then((response) => {
          if (response.code === 200) {
            this.list = response.result;
            this.total = response.total;
          }
        });
      },
      /**
       * 执行删除
       * @param {Object} offerId
       */
      deleteBlackAff() {
        const delArrStr = this.selectionList.join(',');
        deleteOfferWhiteList(delArrStr).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getList();
            if (this.$parent && this.$parent.$parent && this.$parent.$parent.removeAffItem) {
              this.$parent.$parent.removeAffItem(this.deleteAffIds);
            }
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      // 删除操作
      delAffiliate() {
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            // 调用删除操作 传递offerID
            this.deleteBlackAff();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      /**
       * 选择enabledAffiliates监听
       * @param {Object} val
       * val 选择的列
       */
      handleenabledAffiliatesSelectionChange(val) {
        this.selectionList = val.map((item) => item.id);
        this.deleteAffIds = val.map((item) => item.affiliateId);
      },
      // 分页总数监听
      handleenabledAffiliatesSizeChange(val) {
        this.pageSize = val;
        this.handleenabledAffiliatesCurrentChange(1);
      },
      // 分页页数监听
      handleenabledAffiliatesCurrentChange(val) {
        this.currentPage = val;
        this.getList();
      },
      // 跳转aff详情
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId || row.affiliate_id,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      // 打开弹框
      openDialog(type) {
        this.dialog.type = type;
        this.dialog.dialogFormVisible = true;
      },
      // 关闭弹窗
      closeDialog() {
        this.$refs.enabledAffiliatesForm.resetFields();
        this.enabledAffiliatesForm = {
          enabledAffiliates: [
            {
              optionAffiliateIds: [],
              affiliateIds: '',
              clickDailyCap: '',
              enabledAffiliatesItem: [
                {
                  eventName: '',
                  dailyCap: '',
                  payout: '',
                  payoutPercent: '',
                  rate: '',
                  description: '',
                },
              ],
            },
          ],
        };
        this.dialog.dialogFormVisible = false;
      },

      closeUpdateApproveDialog() {
        this.$refs.formUpdateApprove.resetFields();
        this.formUpdateApprove = {
          affiliateId: '',
          eventName: '',
          dailyCap: '',
          payout: '',
          payoutPercent: '',
          adjustRate: '',
          description: '',
          id: '',
        };
        console.log('into closeUpdateApproveDialog');
        this.dialogUpdateApprove.updateApproveDialogFormVisible = false;
      },

      // 提交弹窗
      submitDialog(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.dialog.type == 'create') {
              console.log('调用创建');
              const uniqAff = uniq(
                this.enabledAffiliatesForm.enabledAffiliates.map((item) => item.affiliateIds)
              );
              if (
                uniqAff.length !=
                this.enabledAffiliatesForm.enabledAffiliates.map((item) => item.affiliateIds).length
              ) {
                alert('affiliateIds不能重复，请检查');
                return;
              }
              this.addBtnLoading = true;
              const param = [];
              for (let i = 0; i < this.enabledAffiliatesForm.enabledAffiliates.length; i++) {
                for (
                  let j = 0;
                  j < this.enabledAffiliatesForm.enabledAffiliates[i].enabledAffiliatesItem.length;
                  j++
                ) {
                  param.push({
                    ...this.enabledAffiliatesForm.enabledAffiliates[i].enabledAffiliatesItem[j],
                    affiliateIds: Array.of(
                      this.enabledAffiliatesForm.enabledAffiliates[i].affiliateIds
                    ),
                    clickDailyCap: Number(
                      this.enabledAffiliatesForm.enabledAffiliates[i].clickDailyCap
                    ),
                    offerIds: Array.of(this.offerId),
                  });
                }
              }
              let query = { listPosts: param };

              approvalAfffs(query)
                .then((res) => {
                  console.log(res);
                  if (res.code === 200) {
                    this.tipsData = res.result || [];
                    if (this.tipsData.length > 0) {
                      for (var i = 0; i < this.tipsData.length; i++) {
                        let offerids = this.tipsData[i].offerIds.toString();
                        this.tipsData[i].offerIds = offerids;
                      }
                    }
                    const successAff = this.tipsData
                      .filter((x) => x.type === 'success')
                      .map((item) => item.affiliateId);
                    this.handleAddItem(successAff);
                    this.getList();
                    this.$message({
                      message: '添加成功!',
                      type: 'success',
                    });
                    this.closeDialog();
                  } else {
                    this.$message.error(res.message);
                    this.tipsData = new Array({
                      affiliateId: this.enabledAffiliatesForm.enabledAffiliates.affiliateIds,
                      msg: res.message,
                      offerIds: query.offerIds,
                    });
                  }
                  this.toAffVisible = true;
                  this.addBtnLoading = false;
                })
                .catch((e) => {
                  console.log(e);
                  this.addBtnLoading = false;
                });
            }
          } else {
            console.log('error submit!!');
            this.addBtnLoading = false;
            return false;
          }
        });
      },

      submitUpdateApproveDialog(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let affOfferWhiteListPost = Object.assign({}, this.formUpdateApprove);
            affOfferWhiteListPost.affiliateIds = Array.from([affOfferWhiteListPost.affiliateId]);
            affOfferWhiteListPost.offerIds = Array.from([this.offerId]);
            affOfferWhiteListPost.id = this.formUpdateApprove.id;
            this.updateApproveBtnLoading = true;
            handleApproveUpdate(affOfferWhiteListPost)
              .then((res) => {
                if (res.code === 200) {
                  this.getList();
                  this.$message({
                    message: '修改成功!',
                    type: 'success',
                  });

                  this.closeUpdateApproveDialog();
                } else {
                  this.$message.error(res.message);
                }
                this.updateApproveBtnLoading = false;
              })
              .finally(() => {
                this.updateApproveBtnLoading = false;
              });
          }
        });
      },

      addItem(index, type) {
        if (type === 'child') {
          this.enabledAffiliatesForm.enabledAffiliates[index].enabledAffiliatesItem.push({
            eventName: '',
            dailyCap: '',
            payout: '',
            payoutPercent: '',
            rate: '',
            description: '',
          });
        } else {
          this.enabledAffiliatesForm.enabledAffiliates.push({
            optionAffiliateIds: [],
            affiliateIds: '',
            clickDailyCap: this.enabledAffiliatesForm.enabledAffiliates[0].clickDailyCap,
            enabledAffiliatesItem: this.enabledAffiliatesForm.enabledAffiliates[0].enabledAffiliatesItem.map(
              (item) => {
                return { ...item };
              }
            ),
          });
        }
      },
      delItem(index, childIndex, type) {
        if (type === 'child') {
          this.enabledAffiliatesForm.enabledAffiliates[index].enabledAffiliatesItem.splice(
            childIndex,
            1
          );
        } else {
          this.checkedAffiliateIds.splice(
            this.checkedAffiliateIds.indexOf(
              this.enabledAffiliatesForm.enabledAffiliates[index].affiliateIds
            ),
            1
          );
          this.getUnCheckedAff();
          this.enabledAffiliatesForm.enabledAffiliates.splice(index, 1);
        }
      },
      handleClear(value) {
        this.checkedAffiliateIds.splice(this.checkedAffiliateIds.indexOf(value), 1);
        this.getUnCheckedAff();
      },
      handleChangeAff(val, affiliateIds, index) {
        // 现在的数据，之前的数据，下标
        console.log('valChange===>', val, affiliateIds, index);
        if (affiliateIds !== '' && this.checkedAffiliateIds.indexOf(affiliateIds) > -1) {
          this.checkedAffiliateIds.splice(this.checkedAffiliateIds.indexOf(affiliateIds), 1);
        }
        this.checkedAffiliateIds.push(val);
        this.getUnCheckedAff();
      },
      getUnCheckedAff() {
        // 过滤出未被选择的AffiliateIds数组
        this.optionAffiliateIds = this.affiliatesGroupList.map((item) => {
          return {
            ...item,
            children: item.children.filter((child) => {
              if (this.checkedAffiliateIds.indexOf(child.value) > -1) {
                delete this.affiliatesGroupList[this.affiliatesGroupList.indexOf(child.value)];
              } else {
                return item.children;
              }
            }),
          };
        });
      },
      handleFocusAff(val) {
        this.originAffiliateIds = val;
      },
      getDataSource(index) {
        if (index == 0 && this.enabledAffiliatesForm.enabledAffiliates.length == 1) {
          return this.affiliatesGroupList;
        } else {
          return this.optionAffiliateIds;
        }
      },
      handleAddItem(successAff) {
        const uniqAff = uniq(
          this.enabledAffiliatesForm.enabledAffiliates
            .filter((x) =>
              x.enabledAffiliatesItem.some((item) => {
                return item.eventName === 'all-event' || item.eventName === 'install';
              })
            )
            .map((item) => item.affiliateIds)
        );
        for (let aff of uniqAff) {
          if (aff === 31110001 || aff === 10110006) {
            continue;
          }
          let obj = new Object();
          obj.affiliateId = aff;
          obj.ratio = 10;
          obj.isNew = true;
          try {
            if (
              this.$parent.$parent &&
              this.$parent.$parent.addItem &&
              successAff &&
              successAff.includes(aff)
            ) {
              this.$parent.$parent.addItem(obj);
            }
          } catch (e) {
            console.log('handleAddItem error');
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .row_line {
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 16px;
  }

  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
