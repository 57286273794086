<template>
  <div class="ddj-country">
    <el-card class="offerAffiliateBlacklist" shadow="never" v-if="message !== '1'">
      <div slot="header" class="clearfix">
        <span class="font-14 mb10">Enabled Affiliates</span>
      </div>
      <enabled-affiliates
        :offerId="offerId"
        :dataList="eventCapDaily"
        :affiliatesGroupList="affiliatesGroupList"
        :affiliateLoading="affiliateLoading"
        ref="enabledAffiliates"
      />
    </el-card>
    <div class="mainBox">
      <el-form :model="postData1" size="mini" v-show="message !== '1'">
        <div class="font-14 mb10">其他渠道比例分配</div>
        <div class="border-1 pt10 pb10 pl10 pr10 mb10">
          <el-row
            v-for="(item, index) in postData1.ofAffList"
            :key="index"
            :gutter="10"
            class="mb10"
          >
            <el-col :span="6" :style="item.isNew ? 'background: red' : ''">
              <!-- multiple -->
              <group-select
                v-model="item.affiliateId"
                :data-source="affiliatesGroupList"
                :loading="affiliateLoading"
                clearable
                reserve-keyword
                filterable
                hasAll
                size="mini"
                class="w100"
              />
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="item.ratio"
                size="mini"
                min="0"
                max="100"
                maxlength="3"
                oninput="value=value.replace(/[^\d]/g,'');if(value > 100) {value=100}"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-select v-model="item.replaceRule" clearable filterable class="w100" size="mini">
                <el-option
                  v-for="item in replaeceRules"
                  :key="item.id"
                  :value="item.id"
                  :label="item.id + '-' + item.name"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" @click="addItem({})" size="mini" v-if="index == 0"
                >Add
              </el-button>
              <el-button type="danger" @click="removeItem(index)" size="mini" v-if="index > 0"
                >Delete
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" align="right">
              <!-- <el-button type="primary" size="mini" @click="saveOther()">save</el-button> -->
              <el-button type="danger" size="mini" @click="removeOther()" v-if="type != 'offerList'"
                >Deletes
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-form>

      <el-form
        :model="replaceAffiliateData"
        size="mini"
        v-if="message !== '1' && postData2.strategy !== 'onlyVBA'"
      >
        <div class="font-14 mb10">混量替补渠道及替换规则</div>
        <div class="border-1 pt10 pb10 pl10 pr10 mb10">
          <el-row
            v-for="(item, index) in replaceAffiliateData.replaceAffList"
            :key="index"
            :gutter="10"
            class="mb10"
          >
            <el-col :span="6">
              <group-select
                v-model="item.affiliateIds"
                :data-source="affiliatesGroupList"
                :loading="affiliateLoading"
                clearable
                reserve-keyword
                filterable
                multiple
                hasAll
                size="mini"
                class="w100"
              />
            </el-col>
            <el-col :span="6">
              <el-select v-model="item.ruleId" clearable filterable class="w100" size="mini">
                <el-option
                  v-for="item in replaeceRules"
                  :key="item.id"
                  :value="item.id"
                  :label="item.id + '-' + item.name"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" @click="addItemReplaceRule" size="mini" v-if="index == 0"
                >Add
              </el-button>
              <el-button
                type="danger"
                @click="removeItemReplaceRule(index)"
                size="mini"
                v-if="index > 0"
                >Delete
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" align="right">
              <el-button
                type="danger"
                size="mini"
                @click="removeReplaceAffilate()"
                v-if="type != 'offerList'"
                >Deletes
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-form>

      <el-form :model="postData2" size="mini" ref="postData2" label-width="200px" :rules="rules">
        <div class="font-14 mb10">基础策略</div>
        <div class="border-1 pt10 pb10 pl10 pr10 mb10">
          <el-form-item>
            <el-radio-group v-model="postData2.strategy">
              <el-radio label="vba">VBA优先</el-radio>
              <el-radio label="other">其他渠道优先</el-radio>
              <el-radio label="onlyVBA">OnlyVBA</el-radio>
              <el-radio label="externalAff">外部渠道混量</el-radio>
              <el-radio disabled label="vbaPercent">VBA占比(其它渠道优先）</el-radio>
              <el-radio disabled label="curve">曲线混量</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="Daily VBA ClickCap:"
            prop="vbaDailyClickCap"
            ref="vbaDailyClickCap"
            v-if="postData2.strategy == 'vba' || postData2.strategy == 'onlyVBA'"
          >
            <el-input v-model.number="postData2.vbaDailyClickCap" type="number"></el-input>
          </el-form-item>
          <el-form-item
            label="Daily Other Cap:"
            prop="otherDailyInstallCap"
            v-if="
              postData2.strategy == 'vba' ||
                postData2.strategy == 'other' ||
                postData2.strategy == 'externalAff' ||
                postData2.strategy == 'vbaPercent'
            "
          >
            <el-input
              v-model.number="postData2.otherDailyInstallCap"
              type="number"
              @blur="
                handleCount();
                handleMinCvr();
              "
            ></el-input>
          </el-form-item>
          <!--          <el-form-item
                      label="Other Min Cap:"
                      v-if="postData2.strategy == 'vba'"
                      prop="otherMinInstallCap"
                    >
                      <el-input v-model="postData2.otherMinInstallCap"></el-input>
                    </el-form-item>-->

          <el-form-item
            label="Expect CVR(%):"
            prop="expectCvr"
            key="expectCvr"
            v-if="
              postData2.strategy == 'other' ||
                postData2.strategy == 'vba' ||
                postData2.strategy == 'curve' ||
                postData2.strategy == 'externalAff'
            "
          >
            <el-input
              v-if="
                postData2.strategy == 'other' ||
                  postData2.strategy == 'vba' ||
                  postData2.strategy == 'curve' ||
                  postData2.strategy == 'externalAff'
              "
              v-model="postData2.expectCvr"
              @blur="
                handleCount();
                handleMinCvr();
              "
            ></el-input>
            <el-input v-else v-model="postData2.expectCvr"></el-input>
          </el-form-item>

          <el-form-item
            label="Min CVR(%):"
            prop="minCvr"
            key="minCvr"
            v-if="postData2.strategy == 'curve'"
          >
            <el-input v-if="postData2.strategy == 'curve'" v-model="postData2.minCvr"></el-input>
            <el-input v-else v-model="postData2.minCvr"></el-input>
          </el-form-item>

          <el-form-item
            label="vba click cap:"
            prop="vbaClickCap"
            key="vbaClickCap"
            v-if="postData2.strategy == 'curve'"
          >
            <el-input
              :disabled="true"
              v-if="postData2.strategy == 'curve'"
              v-model="postData2.vbaClickCap"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="all install cap:"
            prop="allCap"
            key="allCap"
            v-if="postData2.strategy == 'curve'"
          >
            <el-input
              :disabled="true"
              v-if="postData2.strategy == 'curve'"
              v-model="postData2.allCap"
            ></el-input>
            <el-input v-else v-model="postData2.allCap"></el-input>
          </el-form-item>

          <el-form-item
            v-if="
              postData2.strategy == 'other' ||
                postData2.strategy == 'vba' ||
                postData2.strategy == 'vbaPercent'
            "
            label="VBA Percentage(%):"
            prop="vbaPercent"
            key="vbaPercent"
          >
            <el-input
              v-model.number="postData2.vbaPercent"
              type="number"
              placeholder="例如 50"
              @change="handleCount"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="Lower limit of cr(%)"
            v-if="postData2.strategy == 'vbaPercent'"
            prop="expectCvr"
          >
            <el-input placeholder="0.03" v-model="postData2.expectCvr"></el-input>
          </el-form-item>

          <el-form-item
            label="预估数据包数量(一天):"
            prop="forecastDataSourceCount"
            v-if="postData2.strategy == 'other'"
          >
            <el-input v-model="postData2.forecastDataSourceCount" disabled></el-input>
          </el-form-item>
          <!--          <el-form-item
                      label="Expect Other Percentage(%):"
                      prop="expectOtherPercent"
                      v-if="postData2.strategy == 'vba'"
                    >
                      <el-input v-model="postData2.expectOtherPercent"></el-input>
                    </el-form-item>-->
          <el-form-item align="right">
            <!-- <el-button type="primary" size="mini" @click="saveStrategy('postData2')"
              >save</el-button
            > -->
            <el-button
              type="danger"
              size="mini"
              @click="removeAutoMixBase()"
              v-if="type != 'offerList'"
              >Deletes
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-form
        :model="postData3"
        size="mini"
        ref="postData3"
        label-width="200px"
        :rules="rules"
        v-show="postData2.strategy != 'externalAff'"
      >
        <div class="font-14 mb10" v-if="type != 'offerList'">投放数据包选择</div>
        <div class="border-1 pt10 pb10 pl10 pr10" v-if="type != 'offerList'">
          <el-form-item label="定时数据包" prop="dataSourceIds">
            <el-select
              v-model="postData3.dataSourceIds"
              placeholder="Please select dataSourceId"
              multiple
              class="w100"
              filterable
              clearable
            >
              <el-option
                v-for="item in options.dataList"
                :key="item.id"
                :label="`${item.deviceName} --> 设备数: ${item.deviceCount}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="实时数据包" prop="dataSourceId">
            <el-select
              v-model="postData3.dataSourceId"
              placeholder="Please select dataSourceId"
              class="w100"
              filterable
              clearable
            >
              <el-option
                v-for="item in options.dataListRealTime"
                :key="item.id"
                :label="`${item.deviceName} --> 设备数: ${item.deviceCount}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form>

      <el-form :model="postData3" size="mini" ref="postData3" label-width="200px" :rules="rules">
        <div class="font-14 mb10" v-show="postData2.strategy != 'externalAff'">数据比例配置</div>
        <div class="border-1 pt10 pb10 pl10 pr10 mb10" v-show="postData2.strategy != 'externalAff'">
          <el-row
            v-for="(item, index) in postData3.dataStrategyVOList"
            :key="index"
            :gutter="10"
            class="mb10"
          >
            <el-col :span="6">
              <el-select
                v-model="item.dataStrategyCode"
                placeholder="Please select"
                class="w100"
                filterable
                clearable
                size="mini"
              >
                <el-option
                  v-for="it in options.strategyList"
                  :key="it.id"
                  :label="it.dataStrategyCode"
                  :value="it.dataStrategyCode"
                />
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="item.percentage"
                size="mini"
                min="0"
                max="1"
                maxlength="4"
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              ></el-input>
            </el-col>

            <el-col :span="6">
              <el-button type="primary" @click="addAlgItem" size="mini" v-if="index == 0"
                >Add
              </el-button>
              <el-button type="danger" @click="removeAlgItem(index)" size="mini" v-if="index > 0"
                >Delete
              </el-button>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="5" v-show="postData2.strategy != 'externalAff' && message !== '1'">
          <el-col :span="3">
            <div class="font-14 mb10">算法全局比例配置</div>
          </el-col>
          <el-col :span="6">
            <el-switch
              v-model="postData3.globalAlgEnable"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="yes"
              inactive-value="no"
            >
            </el-switch>
          </el-col>
        </el-row>

        <el-row :gutter="8" v-show="postData2.strategy != 'externalAff' && message == '1'">
          <el-col :span="4">
            <div class="font-14 mb10">默认数据包</div>
          </el-col>
          <el-col :span="5">
            <el-switch
              v-model="postData3.defaultUploadEnabled"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="yes"
              inactive-value="no"
            >
            </el-switch>
          </el-col>
          <el-col :span="15">
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options.defaultDataList"
                :key="item.id"
                :label="`${item.country} -- ${item.os} --> 设备数: ${item.deviceCount}`"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-row>

        <el-card class="font-14 mb10" shadow="never">
          <div slot="header" class="clearfix">
            <span>子渠道规则</span>
          </div>
          <div>
            <el-row class="detail_row">
              <el-col>
                <el-form-item label="sub rule:" prop="">
                  <el-select
                    v-model="upManagerAndSubRule.ruleId"
                    placeholder="Please select sub rule"
                    filterable
                    class="w100"
                  >
                    <el-option
                      v-for="item in options.rule"
                      :key="item.ruleId"
                      :label="item.ruleName"
                      :value="item.ruleId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <div align="center" class="pt10">
          <el-button type="primary" size="small" @click="saveAll()" :loading="loading.saveAll"
            >Save All
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import offerAutoMixCon from '../../controllers/offer/autoMix';
  import EnabledAffiliates from '@/views/offer/components/EnabledAffiliates.vue';

  export default {
    name: 'OfferAutoMix',
    components: { EnabledAffiliates },
    ...offerAutoMixCon,
  };
</script>

<style></style>
